import {
  Status,
  STATUS_FAILURE,
  STATUS_LOADING,
  STATUS_START,
  STATUS_SUCCESS
} from '../../../types/status';
import {
  ActionTypes,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_START,
  REFRESH_TOKEN_SUCCESS
} from '../../actions/authentication/refreshTokenActions';

export type State = {
  refreshTokenStatus: Status;
  refreshTokenError: null | string;
};

const initialState: State = {
  refreshTokenStatus: STATUS_START,
  refreshTokenError: null
};

// eslint-disable-next-line func-names
// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state: State = initialState,
  action: ActionTypes
): State {
  switch (action.type) {
    case REFRESH_TOKEN_START:
      return {
        ...state,
        refreshTokenStatus: STATUS_LOADING
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        refreshTokenStatus: STATUS_SUCCESS
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        refreshTokenError: action.payload.error,
        refreshTokenStatus: STATUS_FAILURE
      };
    default:
      return state;
  }
}
