export const REFRESH_TOKEN_START = 'REFRESH_TOKEN_START';
export const REFRESH_TOKEN_TOKEN_START = 'REFRESH_TOKEN_TOKEN_START';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_RESET = 'REFRESH_TOKEN_RESET';

export type REFRESH_TOKEN_START_ACTION = {
  type: typeof REFRESH_TOKEN_START;
  payload: { pathname: string };
};

export type REFRESH_TOKEN_SUCCESS_ACTION = {
  type: typeof REFRESH_TOKEN_SUCCESS;
  payload: {};
};

export type REFRESH_TOKEN_FAILURE_ACTION = {
  type: typeof REFRESH_TOKEN_FAILURE;
  payload: {
    error: string | null;
  };
};

export function refreshToken(pathname: string): REFRESH_TOKEN_START_ACTION {
  return {
    type: REFRESH_TOKEN_START,
    payload: { pathname }
  };
}

export function refreshTokenSuccess(): REFRESH_TOKEN_SUCCESS_ACTION {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload: {}
  };
}

export function refreshTokenFailed(
  error: string | null
): REFRESH_TOKEN_FAILURE_ACTION {
  return {
    type: REFRESH_TOKEN_FAILURE,
    payload: { error }
  };
}

export type ActionTypes =
  | REFRESH_TOKEN_START_ACTION
  | REFRESH_TOKEN_SUCCESS_ACTION
  | REFRESH_TOKEN_FAILURE_ACTION;
