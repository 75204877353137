import {
  ASSET_VERSION_PAGE_RESSOURCE,
  HOME_PAGE_RESSOURCE,
  SEARCH_PAGE_RESSOURCE
} from './rules';

/* Common */
export const HOME_PAGE_PATH = '/';
export const SEARCH_PAGE_PATH = '/search';
export const ASSET_VERSION_PAGE_PATH = '/asset/:id/:version';
export const STATUS_PATH = '/status';

export const routes = [
  { pathname: HOME_PAGE_PATH, ressource: HOME_PAGE_RESSOURCE },
  { pathname: SEARCH_PAGE_PATH, ressource: SEARCH_PAGE_RESSOURCE },
  { pathname: ASSET_VERSION_PAGE_PATH, ressource: ASSET_VERSION_PAGE_RESSOURCE }
];

export const getPathnameRessource = (pathname: string) =>
  routes.find((e) => e.pathname === pathname)?.ressource || 'NOT FOUND';
