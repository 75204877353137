import { Suspense, lazy, LazyExoticComponent } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Navigate, RouteObject } from 'react-router';
import SuspenseLoader from '../components/SuspenseLoader/SuspenseLoader';
import {
  ASSET_VERSION_PAGE_PATH,
  HOME_PAGE_PATH,
  SEARCH_PAGE_PATH,
  STATUS_PATH
} from '../constants/route';
import AppLayout from '../layout/AppLayout';
import Status404 from '../pages/Status/Status404/Status404';

import statusRoutes from './statusRoutes';

const Loader =
  (
    Component: LazyExoticComponent<() => JSX.Element> | JSX.IntrinsicAttributes
  ) =>
  (props: JSX.IntrinsicAttributes) =>
    (
      <Suspense fallback={<SuspenseLoader />}>
        {/* @ts-ignore */}
        <Component {...props} />
      </Suspense>
    );

const AssetsPage = Loader(lazy(() => import('../pages/Assets') as any));
const AssetPage = Loader(lazy(() => import('../pages/Asset') as any));

const router: RouteObject[] = [
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: STATUS_PATH,
    children: statusRoutes
  },
  {
    path: HOME_PAGE_PATH,
    element: <Navigate to={SEARCH_PAGE_PATH} replace />
  },
  {
    path: SEARCH_PAGE_PATH,
    element: (
      <AppLayout>
        <AssetsPage />
      </AppLayout>
    )
  },
  {
    path: ASSET_VERSION_PAGE_PATH,
    element: (
      <AppLayout>
        <AssetPage />
      </AppLayout>
    )
  }
];

export default router;
