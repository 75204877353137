import { AssetVersion, SingleAsset } from '../../../types/asset';

export const GET_ASSET_START = 'GET_ASSET_START';
export const GET_ASSET_FAILURE = 'GET_ASSET_FAILURE';
export const GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS';

export const GET_FILE_URL_START = 'GET_FILE_URL_START';
export const GET_FILE_URL_FAILURE = 'GET_FILE_URL_FAILURE';
export const GET_FILE_URL_SUCCESS = 'GET_FILE_URL_SUCCESS';

export const GET_DOWNLOAD_LINK_START = 'GET_DOWNLOAD_LINK_START';
export const GET_DOWNLOAD_LINK_FAILURE = 'GET_DOWNLOAD_LINK_FAILURE';
export const GET_DOWNLOAD_LINK_SUCCESS = 'GET_DOWNLOAD_LINK_SUCCESS';

export const GET_ASSET_VERSIONS_START = 'GET_ASSET_VERSIONS_START';
export const GET_ASSET_VERSIONS_SUCCESS = 'GET_ASSET_VERSIONS_SUCCESS';
export const GET_ASSET_VERSIONS_FAILURE = 'GET_ASSET_VERSIONS_FAILURE';

export const GET_FILE_URL_BY_VERSION_START = 'GET_FILE_URL_BY_VERSION_START';
export const GET_FILE_URL_BY_VERSION_FAILURE =
  'GET_FILE_URL_BY_VERSION_FAILURE';
export const GET_FILE_URL_BY_VERSION_SUCCESS =
  'GET_FILE_URL_BY_VERSION_SUCCESS';

export const GET_PREVIEW_LINK_START = 'GET_PREVIEW_LINK_START';
export const GET_PREVIEW_LINK_FAILURE = 'GET_PREVIEW_LINK_FAILURE';
export const GET_PREVIEW_LINK_SUCCESS = 'GET_PREVIEW_LINK_SUCCESS';

export type GET_ASSET_START_ACTION = {
  type: typeof GET_ASSET_START;
  payload: { id: string; version: string | number | undefined };
};

export type GET_ASSET_SUCCESS_ACTION = {
  type: typeof GET_ASSET_SUCCESS;
  payload: {
    asset: SingleAsset;
  };
};

export type GET_ASSET_FAILURE_ACTION = {
  type: typeof GET_ASSET_FAILURE;
  payload: {
    error: string;
  };
};

export function getAsset(
  id: string,
  version: string | number | undefined
): GET_ASSET_START_ACTION {
  return {
    type: GET_ASSET_START,
    payload: { id, version }
  };
}

export function getAssetSuccess(asset: SingleAsset): GET_ASSET_SUCCESS_ACTION {
  return {
    type: GET_ASSET_SUCCESS,
    payload: { asset }
  };
}

export function getAssetFailed(error: string): GET_ASSET_FAILURE_ACTION {
  return {
    type: GET_ASSET_FAILURE,
    payload: { error }
  };
}

export type GET_FILE_URL_START_ACTION = {
  type: typeof GET_FILE_URL_START;
  payload: { id: string; version: string | number | undefined };
};

export type GET_FILE_URL_SUCCESS_ACTION = {
  type: typeof GET_FILE_URL_SUCCESS;
  payload: {
    url: string;
  };
};

export type GET_FILE_URL_FAILURE_ACTION = {
  type: typeof GET_FILE_URL_FAILURE;
  payload: {
    error: string;
  };
};

export function getFileUrl(
  id: string,
  version: string | number | undefined
): GET_FILE_URL_START_ACTION {
  return {
    type: GET_FILE_URL_START,
    payload: { id, version }
  };
}

export function getFileUrlSuccess(url: string): GET_FILE_URL_SUCCESS_ACTION {
  return {
    type: GET_FILE_URL_SUCCESS,
    payload: { url }
  };
}

export function getFileUrlFailed(error: string): GET_FILE_URL_FAILURE_ACTION {
  return {
    type: GET_FILE_URL_FAILURE,
    payload: { error }
  };
}

export type GET_DOWNLOAD_LINK_START_ACTION = {
  type: typeof GET_DOWNLOAD_LINK_START;
  payload: { id: string | number; assetVersion: number | null };
};

export type GET_DOWNLOAD_LINK_SUCCESS_ACTION = {
  type: typeof GET_DOWNLOAD_LINK_SUCCESS;
  payload: {
    url: string;
  };
};

export type GET_DOWNLOAD_LINK_FAILURE_ACTION = {
  type: typeof GET_DOWNLOAD_LINK_FAILURE;
  payload: {
    error: string;
  };
};

export function getDownloadLink(
  id: string | number,
  assetVersion: number | null
): GET_DOWNLOAD_LINK_START_ACTION {
  return {
    type: GET_DOWNLOAD_LINK_START,
    payload: { id, assetVersion }
  };
}

export function getDownloadLinkSuccess(
  url: string
): GET_DOWNLOAD_LINK_SUCCESS_ACTION {
  return {
    type: GET_DOWNLOAD_LINK_SUCCESS,
    payload: { url }
  };
}

export function getDownloadLinkFailed(
  error: string
): GET_DOWNLOAD_LINK_FAILURE_ACTION {
  return {
    type: GET_DOWNLOAD_LINK_FAILURE,
    payload: { error }
  };
}

export type GET_ASSET_VERSIONS_START_ACTION = {
  type: typeof GET_ASSET_VERSIONS_START;
  payload: { id: string };
};

export type GET_ASSET_VERSIONS_SUCCESS_ACTION = {
  type: typeof GET_ASSET_VERSIONS_SUCCESS;
  payload: {
    id: string;
    currentVersion: number;
    versions: Array<AssetVersion>;
  };
};

export type GET_ASSET_VERSIONS_FAILURE_ACTION = {
  type: typeof GET_ASSET_VERSIONS_FAILURE;
  payload: {
    error: string;
  };
};

export function getAssetVersions(id: string): GET_ASSET_VERSIONS_START_ACTION {
  return {
    type: GET_ASSET_VERSIONS_START,
    payload: { id }
  };
}

export function getAssetVersionsSuccess(
  id: string,
  currentVersion: number,
  versions: Array<AssetVersion>
): GET_ASSET_VERSIONS_SUCCESS_ACTION {
  return {
    type: GET_ASSET_VERSIONS_SUCCESS,
    payload: { id, currentVersion, versions }
  };
}

export function getAssetVersionsFailed(
  error: string
): GET_ASSET_VERSIONS_FAILURE_ACTION {
  return {
    type: GET_ASSET_VERSIONS_FAILURE,
    payload: { error }
  };
}

export type GET_FILE_URL_BY_VERSION_START_ACTION = {
  type: typeof GET_FILE_URL_BY_VERSION_START;
  payload: { id: string; version: number };
};

export type GET_FILE_URL_BY_VERSION_SUCCESS_ACTION = {
  type: typeof GET_FILE_URL_BY_VERSION_SUCCESS;
  payload: {
    id: string;
    version: number;
    url: string;
  };
};

export type GET_FILE_URL_BY_VERSION_FAILURE_ACTION = {
  type: typeof GET_FILE_URL_BY_VERSION_FAILURE;
  payload: {
    error: string;
  };
};

export function getFileUrlByVersion(
  id: string,
  version: number
): GET_FILE_URL_BY_VERSION_START_ACTION {
  return {
    type: GET_FILE_URL_BY_VERSION_START,
    payload: { id, version }
  };
}

export function getFileUrlByVersionSuccess(
  id: string,
  version: number,
  url: string
): GET_FILE_URL_BY_VERSION_SUCCESS_ACTION {
  return {
    type: GET_FILE_URL_BY_VERSION_SUCCESS,
    payload: { id, version, url }
  };
}

export function getFileUrlByVersionFailed(
  error: string
): GET_FILE_URL_BY_VERSION_FAILURE_ACTION {
  return {
    type: GET_FILE_URL_BY_VERSION_FAILURE,
    payload: { error }
  };
}

export type GET_PREVIEW_LINK_START_ACTION = {
  type: typeof GET_PREVIEW_LINK_START;
  payload: { id: string | number; assetVersion: number | null };
};

export type GET_PREVIEW_LINK_SUCCESS_ACTION = {
  type: typeof GET_PREVIEW_LINK_SUCCESS;
  payload: {
    url: string;
  };
};

export type GET_PREVIEW_LINK_FAILURE_ACTION = {
  type: typeof GET_PREVIEW_LINK_FAILURE;
  payload: {
    error: string;
  };
};

export function getPreviewLink(
  id: string | number,
  assetVersion: number | null
): GET_PREVIEW_LINK_START_ACTION {
  return {
    type: GET_PREVIEW_LINK_START,
    payload: { id, assetVersion }
  };
}

export function getPreviewLinkSuccess(
  url: string
): GET_PREVIEW_LINK_SUCCESS_ACTION {
  return {
    type: GET_PREVIEW_LINK_SUCCESS,
    payload: { url }
  };
}

export function getPreviewLinkFailed(
  error: string
): GET_PREVIEW_LINK_FAILURE_ACTION {
  return {
    type: GET_PREVIEW_LINK_FAILURE,
    payload: { error }
  };
}
export type ActionTypes =
  | GET_ASSET_START_ACTION
  | GET_ASSET_SUCCESS_ACTION
  | GET_ASSET_FAILURE_ACTION
  | GET_FILE_URL_START_ACTION
  | GET_FILE_URL_SUCCESS_ACTION
  | GET_FILE_URL_FAILURE_ACTION
  | GET_DOWNLOAD_LINK_START_ACTION
  | GET_DOWNLOAD_LINK_SUCCESS_ACTION
  | GET_DOWNLOAD_LINK_FAILURE_ACTION
  | GET_ASSET_VERSIONS_START_ACTION
  | GET_ASSET_VERSIONS_SUCCESS_ACTION
  | GET_ASSET_VERSIONS_FAILURE_ACTION
  | GET_FILE_URL_BY_VERSION_START_ACTION
  | GET_FILE_URL_BY_VERSION_SUCCESS_ACTION
  | GET_FILE_URL_BY_VERSION_FAILURE_ACTION
  | GET_PREVIEW_LINK_START_ACTION
  | GET_PREVIEW_LINK_SUCCESS_ACTION
  | GET_PREVIEW_LINK_FAILURE_ACTION;
