import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
  AppBar,
  Box,
  Grid,
  IconButton,
  SxProps,
  Theme,
  Toolbar,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Menu } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import DrawerMenu from './DrawerMenu';
import MyTheme from '../theme/MyTheme';
import EndSectionDesktopHeader from './EndSectionDesktopHeader';
import {
  COLOR_BLACK,
  COLOR_WHITE,
  DECATHLON_LIGHT_GREEN_COLOR
} from '../constants/colors';
import { getDrawerItemsByRole } from '../constants/header';
import DefaultTooltip from '../components/tooltip/DefaultTooltip';
import { redirectTo } from '../utils/utils';
import { HOME_PAGE_PATH } from '../constants/route';
import HeaderSearch from './HeaderSearch';

import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../redux/reducers/store';
import { setHeaderHeight } from '../redux/actions/assets/assetsActions';
import get from 'lodash/get';
import { STATUS_LOADING, STATUS_SUCCESS } from '../types/status';
import usePrevious from '../hooks/usePrevious';
import { getDecodedJWT, getToken } from '../auth/session';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const sxStyles: Record<string, SxProps<Theme>> = {
  appBar: {
    zIndex: 101,
    backgroundColor: COLOR_WHITE,
    boxShadow: '0px 5px 15px -3px rgba(0,0,0,0.1)'
  },
  menuButton: {
    marginRight: MyTheme.spacing(2),
    [MyTheme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  desktopContainerItems: {
    marginLeft: MyTheme.spacing(3),
    marginRight: MyTheme.spacing(3)
  },
  menuItem: {
    cursor: 'pointer',
    height: 90,
    padding: MyTheme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  isSelected: {
    backgroundColor: MyTheme.palette.primary.main,
    color: COLOR_WHITE
  },
  hoverItem: {
    color: COLOR_BLACK,
    '&:hover': {
      backgroundColor: DECATHLON_LIGHT_GREEN_COLOR,
      color: COLOR_WHITE
    }
  },
  title: {
    fontWeight: 500,
    display: 'none',
    textAlign: 'center',
    [MyTheme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  grow: {
    flexGrow: 1
  }
};

const useStyles = makeStyles({
  image: {
    width: 100,
    cursor: 'pointer',
    maxHeight: 50,
    [MyTheme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
});

const Header: FC = () => {
  const headerRef = useRef<HTMLDivElement>();

  const token = getToken();
  const decodedJwt = getDecodedJWT(token);

  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { totalCount, status } = useSelector((state: StateType) => ({
    totalCount: state.assets.totalCount,
    status: state.assets.status
  }));
  const headerHeight = useSelector(
    (state: StateType) => state.assets.headerHeight
  );
  const previousStatus = usePrevious(status);
  const [totalCountState, setTotalCountState] = useState(0);

  const [isOpenDrawer, setOpenDrawer] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const logout = () => {
    localStorage.clear();
  };

  const openDrawer = () => setOpenDrawer(true);
  const closeDrawer = () => setOpenDrawer(false);

  const { drawerItems1, drawerItems2 } = getDrawerItemsByRole();

  useLayoutEffect(() => {
    dispatch(setHeaderHeight(get(headerRef, ['current', 'clientHeight'], 0)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerHeight, totalCount]);

  useEffect(() => {
    if (previousStatus === STATUS_LOADING && status === STATUS_SUCCESS) {
      setTotalCountState(totalCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousStatus, status]);

  return (
    <>
      <AppBar
        ref={headerRef as React.RefObject<HTMLDivElement>}
        position="fixed"
        sx={sxStyles.appBar}
      >
        <Toolbar>
          <DefaultTooltip title={t('decathlon_menu_tooltip') as string}>
            <IconButton
              size="small"
              edge="start"
              sx={sxStyles.menuButton}
              color="default"
              onClick={openDrawer}
            >
              <Menu />
            </IconButton>
          </DefaultTooltip>
          <img
            alt="Logo DECATHLON"
            src={`${process.env.PUBLIC_URL}/ShareLogo.svg`}
            className={classes.image}
            onClick={redirectTo(navigate, HOME_PAGE_PATH)}
          />
          <Grid
            container
            sx={sxStyles.desktopContainerItems}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <HeaderSearch />
            </Grid>
            {location.pathname === HOME_PAGE_PATH && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  color: 'black',
                  textAlign: 'center',
                  marginBottom: 1,
                  minHeight: 25
                }}
              >
                {status !== STATUS_LOADING && (
                  <Typography component="span">{`${totalCountState}  ${t(
                    'decathlon_results_sentence'
                  )}`}</Typography>
                )}
              </Grid>
            )}
          </Grid>
          <Box sx={sxStyles.grow} />
          <EndSectionDesktopHeader otherButtons={drawerItems2} />
          <DefaultTooltip
            title={
              decodedJwt
                ? (decodedJwt?.user as { displayName: string })?.displayName
                : ''
            }
          >
            <AccountCircleIcon htmlColor="black" />
          </DefaultTooltip>
        </Toolbar>
      </AppBar>
      <DrawerMenu
        closeDrawer={closeDrawer}
        isOpenDrawer={isOpenDrawer}
        drawerItemsTop={drawerItems1}
        drawerItemsBottom={drawerItems2}
      />
    </>
  );
};

export default Header;
