import { useEffect, useRef } from 'react';

type IntervalFunction = () => unknown | void;

const useInterval = (callback: IntervalFunction, delay: number) => {
  const savedCallback = useRef<IntervalFunction | null>(null);
  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {
      /* DO SOMETHING */
    };
  }, [delay]);
};

export default useInterval;
