import { AssetVersion, SingleAsset } from '../../../types/asset';

import {
  Status,
  STATUS_FAILURE,
  STATUS_LOADING,
  STATUS_START,
  STATUS_SUCCESS
} from '../../../types/status';

import {
  ActionTypes,
  GET_ASSET_FAILURE,
  GET_ASSET_START,
  GET_ASSET_SUCCESS,
  GET_ASSET_VERSIONS_FAILURE,
  GET_ASSET_VERSIONS_START,
  GET_ASSET_VERSIONS_SUCCESS,
  GET_DOWNLOAD_LINK_FAILURE,
  GET_DOWNLOAD_LINK_START,
  GET_DOWNLOAD_LINK_SUCCESS,
  GET_FILE_URL_BY_VERSION_FAILURE,
  GET_FILE_URL_BY_VERSION_START,
  GET_FILE_URL_BY_VERSION_SUCCESS,
  GET_FILE_URL_FAILURE,
  GET_FILE_URL_START,
  GET_FILE_URL_SUCCESS,
  GET_PREVIEW_LINK_FAILURE,
  GET_PREVIEW_LINK_START,
  GET_PREVIEW_LINK_SUCCESS
} from '../../actions/asset/assetActions';
import get from 'lodash/get';

export type State = {
  asset: SingleAsset | null;
  status: Status;
  error: null | string;

  url: string | null;
  statusFileUrl: Status;
  errorFileUrl: null | string;

  downloadLink: string | null;
  statusDownloadLink: Status;
  errorDownloadLink: null | string;

  assetId: string | null;
  currentVersion: number | null;
  assetVersions: Array<AssetVersion>;
  statusAssetVersions: Status;
  errorAssetVersions: null | string;

  urlByIdAndVersion: Record<string, Record<string, { url: string }>>;
  statusFileUrlByVersion: Status;
  errorFileUrlByVersion: null | string;

  previewLink: string | null;
  statusPreviewLink: Status;
  errorPreviewLink: null | string;
};

const initialState: State = {
  asset: null,
  status: STATUS_START,
  error: null,

  url: null,
  statusFileUrl: STATUS_START,
  errorFileUrl: null,

  downloadLink: null,
  statusDownloadLink: STATUS_START,
  errorDownloadLink: null,

  assetId: null,
  currentVersion: null,
  assetVersions: [],
  statusAssetVersions: STATUS_START,
  errorAssetVersions: null,

  urlByIdAndVersion: {},
  statusFileUrlByVersion: STATUS_START,
  errorFileUrlByVersion: null,

  previewLink: null,
  statusPreviewLink: STATUS_START,
  errorPreviewLink: null
};

// eslint-disable-next-line func-names
// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state: State = initialState,
  action: ActionTypes
): State {
  switch (action.type) {
    case GET_ASSET_START:
      return {
        ...initialState,
        status: STATUS_LOADING
      };
    case GET_ASSET_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        asset: action.payload.asset
      };
    case GET_ASSET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        status: STATUS_FAILURE
      };
    case GET_FILE_URL_START:
      return {
        ...state,
        statusFileUrl: STATUS_LOADING,
        url: initialState.url,
        errorFileUrl: initialState.errorFileUrl
      };
    case GET_FILE_URL_SUCCESS:
      return {
        ...state,
        statusFileUrl: STATUS_SUCCESS,
        url: action.payload.url
      };
    case GET_FILE_URL_FAILURE:
      return {
        ...state,
        errorFileUrl: action.payload.error,
        statusFileUrl: STATUS_FAILURE
      };
    case GET_DOWNLOAD_LINK_START:
      return {
        ...state,
        statusDownloadLink: STATUS_LOADING,
        downloadLink: initialState.downloadLink,
        errorDownloadLink: initialState.errorDownloadLink
      };
    case GET_DOWNLOAD_LINK_SUCCESS:
      return {
        ...state,
        statusDownloadLink: STATUS_SUCCESS,
        downloadLink: action.payload.url
      };
    case GET_DOWNLOAD_LINK_FAILURE:
      return {
        ...state,
        errorDownloadLink: action.payload.error,
        statusDownloadLink: STATUS_FAILURE
      };
    case GET_ASSET_VERSIONS_START:
      return {
        ...state,
        statusAssetVersions: STATUS_LOADING,
        assetVersions: initialState.assetVersions,
        errorAssetVersions: initialState.errorAssetVersions
      };
    case GET_ASSET_VERSIONS_SUCCESS:
      return {
        ...state,
        statusAssetVersions: STATUS_SUCCESS,
        assetId: action.payload.id,
        currentVersion: action.payload.currentVersion,
        assetVersions: action.payload.versions
      };
    case GET_ASSET_VERSIONS_FAILURE:
      return {
        ...state,
        errorAssetVersions: action.payload.error,
        statusAssetVersions: STATUS_FAILURE
      };

    case GET_FILE_URL_BY_VERSION_START:
      return {
        ...state,
        statusFileUrlByVersion: STATUS_LOADING,
        errorFileUrlByVersion: initialState.errorFileUrl
      };
    case GET_FILE_URL_BY_VERSION_SUCCESS:
      return {
        ...state,
        statusFileUrlByVersion: STATUS_SUCCESS,
        urlByIdAndVersion: {
          ...state.urlByIdAndVersion,
          [action.payload.id]: {
            ...get(state, ['urlByIdAndVersion', action.payload.id]),
            [action.payload.version]: { url: action.payload.url }
          }
        }
      };
    case GET_FILE_URL_BY_VERSION_FAILURE:
      return {
        ...state,
        errorFileUrlByVersion: action.payload.error,
        statusFileUrlByVersion: STATUS_FAILURE
      };

    case GET_PREVIEW_LINK_START:
      return {
        ...state,
        statusPreviewLink: STATUS_LOADING,
        previewLink: initialState.previewLink,
        errorPreviewLink: initialState.errorPreviewLink
      };
    case GET_PREVIEW_LINK_SUCCESS:
      return {
        ...state,
        statusPreviewLink: STATUS_SUCCESS,
        previewLink: action.payload.url
      };
    case GET_PREVIEW_LINK_FAILURE:
      return {
        ...state,
        errorPreviewLink: action.payload.error,
        statusPreviewLink: STATUS_FAILURE
      };
    default:
      return state;
  }
}
