import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { refreshToken } from '../redux/actions/authentication/refreshTokenActions';

import useInterval from './useInterval';

export default function useRefreshToken() {
  const dispatch = useDispatch();
  const location = useLocation();

  const laps = 10 * 60 * 1000; // 30 minutes

  useInterval(() => {
    dispatch(refreshToken(location.pathname));
  }, laps);
}
