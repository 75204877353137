export function setFilterPreference(facet: string, value: boolean): void {
  const currentPreferences = getPreferences();
  currentPreferences[facet] = value;
  localStorage.setItem('preferences', JSON.stringify(currentPreferences));
}

export function getFilterPreference(facet: string) {
  return getPreferences()?.[facet] ?? true;
}

export function getPreferences() {
  return JSON.parse(localStorage.getItem('preferences') || '{}');
}
