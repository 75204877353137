import api from '../api';
import { handleLoginError, refreshTokenError } from './authenticationHelper';
import { getToken } from '../../auth/session';

export function loginUser(originUrl: string): Promise<{}> {
  return api
    .post(`/v1/auth/saml/login?originUrl=${originUrl}`, {})
    .then((response) => response.data)
    .catch(handleLoginError);
}

export function refreshToken(): Promise<{ token: string }> {
  const access_token = getToken();
  return api
    .get('/v1/auth/jwt/refresh', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${access_token}`
      }
    })
    .then((response) => response.data)
    .catch(refreshTokenError);
}
