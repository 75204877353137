import { NavigateFunction } from 'react-router';
import get from 'lodash/get';
import {
  AggregationsType,
  AutocompleteFacetType,
  BucketType,
  WebAppConfig,
  ObjectUnionType,
  SubQueryType,
  TypeOnChangeValue
} from '../types/logic';
import { getFilterPreference } from '../preferences/preferences';

import isEmpty from 'lodash/isEmpty';
import { AssetVersion } from '../types/asset';

export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const redirectTo =
  (navigate: NavigateFunction, pathname: string) => () =>
    navigate(pathname);

export const sortStringAsc = (
  elements: ObjectUnionType[],
  accessor: Array<string>
) =>
  elements.sort((a: ObjectUnionType, b: ObjectUnionType) => {
    if (get(a, accessor) < get(b, accessor)) {
      return -1;
    }
    if (get(a, accessor) > get(b, accessor)) {
      return 1;
    }
    return 0;
  });

export const retrieveSelectedValues = (
  facets: AggregationsType,
  facet: string
) => {
  return (
    get(facets, [facet, facet, 'buckets'], []) as Array<{
      selected: boolean;
      key: string;
      doc_count: number;
    }>
  ).reduce((acc: Array<AutocompleteFacetType>, e) => {
    if (e.selected) {
      return [
        ...acc,
        {
          value: e.key,
          label: e.key,
          doc_count: e.doc_count
        }
      ];
    }
    return acc;
  }, []);
};

export const getFormattedDate = (timestamp: number | undefined) => {
  if (timestamp) {
    var date = new Date(timestamp);
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    return `${day > 9 ? day : '0' + day}/${
      month > 9 ? month : '0' + month
    }/${year}`;
  }
  return null;
};

export const getFacetsFromAggregations = (
  previousFacets: AggregationsType,
  newFacets: AggregationsType,
  languageFacet: string,
  languageZXX: string
) => {
  return Object.entries(newFacets).reduce((acc, [facet, facetAggregation]) => {
    let doc_count_bucketZXX: number;
    if (facet === languageFacet) {
      doc_count_bucketZXX =
        facetAggregation[facet].buckets.find((e) => e.key === languageZXX)
          ?.doc_count || 0;
    }

    const addSelectedFlagToAggregation = {
      ...facetAggregation,
      [facet]: {
        buckets: facetAggregation[facet].buckets.reduce(
          (accumulator: BucketType[], bucket) => {
            const currentFacet = get(previousFacets, [facet, facet], null);
            const currentBucket = currentFacet?.buckets?.find(
              (e: { key: string }) => e.key === bucket.key
            );
            if (currentBucket) {
              return [
                ...accumulator,
                {
                  ...currentBucket,
                  ...bucket,
                  ...(facet === languageFacet && {
                    doc_count: bucket.doc_count + doc_count_bucketZXX
                  })
                }
              ];
            }

            return [...accumulator, bucket];
          },
          []
        )
      }
    };

    return { ...acc, [facet]: addSelectedFlagToAggregation };
  }, {}) as any as AggregationsType;
};

export const getFacetsOptions = (
  values: AutocompleteFacetType[],
  facets: AggregationsType,
  facet: string,
  languageFacet: string,
  languageZXX: string
): TypeOnChangeValue[] => {
  const buckets = (
    get(facets, [facet, facet, 'buckets'], []) as Array<BucketType>
  ).reduce((acc: Array<BucketType>, curr) => {
    if (facet === languageFacet && curr.key === languageZXX) {
      return acc;
    }
    return [...acc, curr];
  }, []);

  const facetsOptions = (buckets as Array<BucketType>)
    .reduce(
      (
        acc: Array<AutocompleteFacetType & { selected: boolean }>,
        e: BucketType
      ) => {
        const disabled = e?.doc_count === 0;
        const selected = !!values.find((a) => a.value === e.key);
        if (disabled && !selected) {
          return acc;
        }
        return [
          ...acc,
          {
            value: e.key,
            label: `${e.key} ${
              typeof e?.doc_count === 'number' ? `(${e?.doc_count})` : ''
            }`,
            doc_count: e.doc_count,
            selected: e.selected
          }
        ];
      },
      []
    )
    .reduce(
      (
        acc: {
          selected: Array<AutocompleteFacetType>;
          others: Array<AutocompleteFacetType>;
        },
        curr: AutocompleteFacetType & { selected: boolean }
      ) => {
        const { selected, ...others } = curr;
        if (selected) {
          return {
            ...acc,
            selected: [...acc.selected, others],
            others: [...acc.others]
          };
        }
        return {
          ...acc,
          selected: [...acc.selected],
          others: [...acc.others, others]
        };
      },
      { selected: [], others: [] }
    );

  const selected = facetsOptions.selected;
  const others = facetsOptions.others;

  return [
    ...(selected as AutocompleteFacetType[]).sort(
      (a, b) => b.doc_count - a.doc_count
    ),
    ...(others as AutocompleteFacetType[]).sort(
      (a, b) => b.doc_count - a.doc_count
    )
  ];
};

const buildFilter = (
  key: string,
  buckets: Array<BucketType>,
  languageFacet: string,
  languageZXX: string
) => {
  const filter = buckets
    .filter((item) => item.selected)
    .map((v) => {
      return { term: { [key]: v.key } };
    });

  if (key === languageFacet && filter.length > 0) {
    filter.push({ term: { [key]: languageZXX } });
  }

  return filter;
};

const buildMust = (
  facets: AggregationsType,
  facet: string,
  languageFacet: string,
  languageZXX: string
) => {
  return Object.keys(facets)
    .filter((key) => facet !== key)
    .map((key) =>
      buildFilter(key, facets[key][key].buckets, languageFacet, languageZXX)
    )
    .reduce((acc: Array<unknown>, should) => {
      if (should.length > 0) {
        return [...acc, { bool: { should } }];
      }
      return acc;
    }, []);
};

export const buildFilters = (
  facets: AggregationsType,
  languageFacet: string,
  languageZXX: string
) => {
  const initialPostFilter = { bool: { must: [] } };
  const postFilter: { bool: { must: Array<unknown> } } = initialPostFilter;
  const aggs = Object.keys(facets).reduce((acc, facet) => {
    const must = buildMust(facets, facet, languageFacet, languageZXX);

    // Post filters
    const shouldPostFilter = buildFilter(
      facet,
      facets[facet][facet].buckets,
      languageFacet,
      languageZXX
    );
    if (shouldPostFilter.length > 0) {
      postFilter.bool.must.push({ bool: { should: shouldPostFilter } });
    }

    return {
      ...acc,
      [facet]: {
        filter: { bool: { must } },
        aggs: {
          [facet]: {
            terms: {
              field: `${facet}`,
              min_doc_count: 0,
              size: 1000
            }
          }
        }
      }
    };
  }, {});

  return {
    aggs,
    postFilter
  };
};

export const buildQuery = (
  query: Record<string, any>,
  facets: AggregationsType,
  sort: Array<{ [x: string]: 'asc' | 'desc' }>,
  tieBreaker: Array<string> | null,
  config: WebAppConfig
) => {
  let objectQuery: SubQueryType = {};
  const stringQuery = query.term;
  if (!isEmpty(stringQuery)) {
    objectQuery.multi_match = {
      query: `${stringQuery}`,
      fields: config?.search?.fields,
      operator: config?.search?.operator,
      type: config?.search?.type,
      fuzziness: config?.search?.fuzziness,
      minimum_should_match: config?.search?.minimumShouldMatch
    };
  } else if (!query.term || query.term === '') {
    objectQuery.match_all = {};
  } else {
    objectQuery = query;
  }

  const queryFilters = (config.queryFilters || [])
    .filter(({ facet }) => getFilterPreference(facet))
    .reduce((acc: Array<{ [x: string]: any }>, { filter }) => {
      acc.push(filter);
      return acc;
    }, []);

  const finalObjectQuery = {
    bool: {
      must: objectQuery,
      ...(queryFilters.length > 0 && { filter: queryFilters })
    }
  };

  const buildedFilters = buildFilters(
    facets,
    config.languageFacet,
    config.languageZXX
  );

  const q = {
    track_total_hits: true,
    ...{ query: finalObjectQuery },
    ...{ from: 0 },
    ...{ size: config?.search?.searchSize || 40 },
    ...{ sort },
    ...{ aggs: buildedFilters.aggs },
    ...{ post_filter: buildedFilters.postFilter },
    ...(tieBreaker && !isEmpty(tieBreaker) && { search_after: tieBreaker })
  };

  return q;
};

export const onChangeFacets = (
  facets: AggregationsType,
  facet: string,
  value: TypeOnChangeValue | TypeOnChangeValue[]
): any => {
  const values = value as AutocompleteFacetType[];
  const buckets = get(facets, [facet, facet, 'buckets'], []);
  return {
    ...facets,
    [facet]: {
      meta: {},
      [facet]: {
        doc_count_error_upper_bound: 0,
        sum_other_doc_count: 0,
        buckets: buckets.map((e) => {
          const selected = values.map((e) => e.value).includes(e.key);
          return {
            ...e,
            selected,
            ...(selected && { timestamp: e.timestamp || Date.now() })
          };
        })
      }
    }
  };
};

export const onResetFilters = (facets: AggregationsType) => {
  return Object.entries(facets).reduce((acc, [facet, v]) => {
    return {
      ...acc,
      [facet]: {
        ...v,
        [facet]: {
          ...get(v, [facet], null),
          buckets: get(v, [facet, 'buckets'], []).map((e) => {
            return {
              ...e,
              selected: false,
              timestamp: null
            };
          })
        }
      }
    };
  }, {});
};

export const onResetFilter = (
  facets: AggregationsType,
  facet: string,
  value: string
) => {
  const buckets: BucketType[] = get(facets, [facet, facet, 'buckets'], []);

  return {
    ...facets,
    [facet]: {
      ...get(facets, [facet], null),
      [facet]: {
        ...get(facets, [facet, facet], null),
        buckets: buckets.map((e) => {
          if (e.key === value) {
            return { ...e, selected: false };
          }
          return e;
        })
      }
    }
  } as AggregationsType;
};

export const hasOneOrMoreFilterApplied = (facets: AggregationsType) => {
  return Object.entries(facets).find(([facet, e]) =>
    get(e, [facet, 'buckets'], []).find((bucket) => bucket.selected)
  );
};

export const getInitialFacets = (
  facetsUsed: Array<{ facet: string; label: string }>,
  facets: AggregationsType
) => {
  const initialFacets: AggregationsType = facetsUsed.reduce(
    (accumulator: any, facet) => {
      const currentFacet = get(facets, [facet.facet], null);
      return {
        ...accumulator,
        [facet.facet]: {
          meta: {},
          [facet.facet]: {
            doc_count_error_upper_bound: get(
              currentFacet,
              [facet.facet, 'doc_count_error_upper_bound'],
              0
            ),
            sum_other_doc_count: get(
              currentFacet,
              [facet.facet, 'sum_other_doc_count'],
              0
            ),
            buckets: get(currentFacet, [facet.facet, 'buckets'], [])
          }
        }
      };
    },
    {}
  );
  return initialFacets;
};

export const handleMetadataType = (
  webApp: WebAppConfig,
  asset: AssetVersion | undefined,
  metadata: string | Array<string>,
  type: 'string' | 'timestamp' | 'array' | 'concat' | 'isPublic' | 'languages',
  objectAccessor?: string
) => {
  if (type === 'string') {
    return get(asset, metadata, null);
  }
  if (type === 'timestamp') {
    return getFormattedDate(get(asset, metadata, null));
  }
  if (type === 'array' && objectAccessor) {
    return get(asset, metadata, [])
      .reduce((acc: Array<string>, curr: Record<string, unknown>) => {
        const val = get(curr, objectAccessor as string);
        if (val) {
          return [...acc, val];
        }
        return [...acc];
      }, [])
      .join(', ');
  }
  if (type === 'array') {
    return get(asset, metadata, []).join(', ');
  }
  if (type === 'concat') {
    return (metadata as Array<string>).map((m) => get(asset, m, '')).join('');
  }
  if (type === 'isPublic' && get(asset, metadata[0]) === 'Public') {
    return get(asset, metadata[1], null);
  }
  if (type === 'languages') {
    const languages: Array<any> = get(asset, metadata, []);
    if (languages?.find((lang) => lang === webApp?.languageZXX)) {
      return 'no text';
    }

    return languages
      .reduce((acc: Array<string>, curr: string) => {
        return [...acc, curr];
      }, [])
      .join(', ');
  }
};
