import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  getAssetFailed,
  getAssetSuccess,
  getAssetVersionsFailed,
  getAssetVersionsSuccess,
  getDownloadLinkFailed,
  getDownloadLinkSuccess,
  getFileUrlByVersionFailed,
  getFileUrlByVersionSuccess,
  getFileUrlFailed,
  getFileUrlSuccess,
  getPreviewLinkFailed,
  getPreviewLinkSuccess,
  GET_ASSET_START,
  GET_ASSET_START_ACTION,
  GET_ASSET_VERSIONS_START,
  GET_ASSET_VERSIONS_START_ACTION,
  GET_DOWNLOAD_LINK_START,
  GET_DOWNLOAD_LINK_START_ACTION,
  GET_FILE_URL_BY_VERSION_START,
  GET_FILE_URL_BY_VERSION_START_ACTION,
  GET_FILE_URL_START,
  GET_FILE_URL_START_ACTION,
  GET_PREVIEW_LINK_START,
  GET_PREVIEW_LINK_START_ACTION
} from '../actions/asset/assetActions';
import * as assetService from '../../services/asset/assetService';
import { AssetVersion, SingleAsset } from '../../types/asset';

function* getAsset(action: GET_ASSET_START_ACTION): any {
  try {
    if (action.payload.version) {
      const asset: SingleAsset = yield call(
        assetService.getAssetVersion,
        action.payload.id,
        action.payload.version
      );
      yield put(getAssetSuccess(asset));
    } else {
      const asset: SingleAsset = yield call(
        assetService.getAsset,
        action.payload.id
      );
      yield put(getAssetSuccess(asset));
    }
  } catch (e: unknown) {
    yield put(getAssetFailed((e as { message: string })?.message));
  }
}

function* getFileUrl(action: GET_FILE_URL_START_ACTION): any {
  try {
    if (action.payload.version) {
      const response: { url: string } = yield call(
        assetService.getFileUrlByVersion,
        action.payload.id,
        'preview',
        action.payload.version
      );
      yield put(getFileUrlSuccess(response.url));
    } else {
      const response: { url: string } = yield call(
        assetService.getFileUrl,
        action.payload.id,
        'preview'
      );
      yield put(getFileUrlSuccess(response.url));
    }
  } catch (e: unknown) {
    yield put(getFileUrlFailed((e as { message: string })?.message));
  }
}

function* getDownloadLink(action: GET_DOWNLOAD_LINK_START_ACTION): any {
  try {
    if (action.payload.assetVersion) {
      const response = yield call(
        assetService.getFileUrlByVersion,
        action.payload.id as string,
        'master',
        action.payload.assetVersion as number
      );
      yield put(getDownloadLinkSuccess(response.url));
    } else {
      const response: { url: string } = yield call(
        assetService.getFileUrl,
        action.payload.id as string,
        'master'
      );
      yield put(getDownloadLinkSuccess(response.url));
    }
  } catch (e: unknown) {
    yield put(getDownloadLinkFailed((e as { message: string })?.message));
  }
}

function* getPreviewLink(action: GET_PREVIEW_LINK_START_ACTION): any {
  try {
    if (action.payload.assetVersion) {
      const response = yield call(
        assetService.getFileUrlByVersion,
        action.payload.id as string,
        'master',
        action.payload.assetVersion as number
      );
      yield put(getPreviewLinkSuccess(response.url));
    } else {
      const response: { url: string } = yield call(
        assetService.getFileUrl,
        action.payload.id as string,
        'master'
      );
      yield put(getPreviewLinkSuccess(response.url));
    }
  } catch (e: unknown) {
    yield put(getPreviewLinkFailed((e as { message: string })?.message));
  }
}

function* getAssetVersions(action: GET_ASSET_VERSIONS_START_ACTION): any {
  try {
    const response: {
      currentVersion: number;
      id: string;
      versions: Array<AssetVersion>;
    } = yield call(assetService.getAssetVersions, action.payload.id);

    yield put(
      getAssetVersionsSuccess(
        response?.id,
        response?.currentVersion,
        response.versions
      )
    );
  } catch (e: unknown) {
    yield put(getAssetVersionsFailed((e as { message: string })?.message));
  }
}

function* getFileUrlByVersion(
  action: GET_FILE_URL_BY_VERSION_START_ACTION
): any {
  try {
    const response: { url: string } = yield call(
      assetService.getFileUrlByVersion,
      action.payload.id,
      'preview',
      action.payload.version
    );

    yield put(
      getFileUrlByVersionSuccess(
        action.payload.id,
        action.payload.version,
        response.url
      )
    );
  } catch (e: unknown) {
    yield put(getFileUrlByVersionFailed((e as { message: string })?.message));
  }
}

export default function* facetSaga() {
  yield takeLatest(GET_ASSET_START, getAsset);
  yield takeLatest(GET_FILE_URL_START, getFileUrl);
  yield takeLatest(GET_DOWNLOAD_LINK_START, getDownloadLink);
  yield takeLatest(GET_ASSET_VERSIONS_START, getAssetVersions);
  yield takeEvery(GET_FILE_URL_BY_VERSION_START, getFileUrlByVersion);
  yield takeLatest(GET_PREVIEW_LINK_START, getPreviewLink);
}
