import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
/* import LanguageDetector from 'i18next-browser-languagedetector'; */
import enJSON from './translations/en';
import frJSON from './translations/fr';

import { enumLanguage } from '../types/enumeration';

const resources = {
  en: { translation: enJSON },
  fr: { translation: frJSON }
};

i18n
  /*   .use(LanguageDetector) */
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    fallbackLng: enumLanguage.fr,
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
