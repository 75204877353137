import { fork, all, spawn } from 'redux-saga/effects';
import authenticationSaga from './authenticationSaga';
import assetsSaga from './assetsSaga';
import assetSaga from './assetSaga';
import configSaga from './configSaga';

// eslint-disable-next-line func-names
// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  yield fork(bootstrap);
}

function* bootstrap() {
  try {
    yield all([
      spawn(authenticationSaga),
      spawn(assetsSaga),
      spawn(assetSaga),
      spawn(configSaga)
    ]);
  } catch (e) {
    // @TODO
  }
}
