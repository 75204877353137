/* Common */
export const HOME_PAGE_RESSOURCE = 'HOME_PAGE_RESSOURCE';
export const SEARCH_PAGE_RESSOURCE = 'SEARCH_PAGE_RESSOURCE';
export const ASSET_VERSION_PAGE_RESSOURCE = 'ASSET_VERSION_PAGE_RESSOURCE';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const commonRessources = [
  HOME_PAGE_RESSOURCE,
  SEARCH_PAGE_RESSOURCE,
  ASSET_VERSION_PAGE_RESSOURCE
];

const rules = {};

export default rules;
