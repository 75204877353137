const enJSON = {
  decathlon_show_filters: 'Show filters',
  decathlon_hide_filters: 'Hide filters',
  decathlon_reset: 'Reset',
  decathlon_damAssetCategory_label: 'Categories',
  decathlon_damPresetView_label: 'Preset View',
  decathlon_topics_label: 'Topics',
  decathlon_rights_label: 'Rights',
  decathlon_domain_label: 'Domain responsible',
  decathlon_provenance_label: 'Provenance flow',
  decathlon_itemType_label: 'Item type',
  decathlon_sport_label: 'Sports',
  decathlon_brand_label: 'Brand',
  decathlon_productLevel1_label: 'Product level 1',
  decathlon_productLevel2_label: 'Product level 2',
  decathlon_productLevel3_label: 'Product level 3',
  decathlon_productNature_label: 'Product Natures',
  decathlon_productItem_label: 'Product Items',
  decathlon_supplierCode_label: 'Supplier CNUF',
  decathlon_process_label: 'Process',
  decathlon_componentLevel1_label: 'Component level 1',
  decathlon_componentLevel2_label: 'Component level 2',
  decathlon_componentNature_label: 'Component nature',
  decathlon_language_label: 'Languages',
  decathlon_countryOfDistribution_label: 'Countries',
  decathlon_audience_label: 'Audience',
  decathlon_media_label: 'Media',
  decathlon_selected_filters_label: 'Selected filters',
  decathlon_clear_all_filters: 'Clear all filters',
  decathlon_no_matches: 'We are sad, nothing matches',
  decathlon_try_another_search: 'Please try another search...',
  decathlon_autocomplete_no_options_text: 'No options available',
  decathlon_autocomplete_loading_text: 'Loading...',
  decathlon_filter_category_label: 'Show Legal Documents',
  decathlon_menu_tooltip: 'Menu',
  decathlon_scroll_to_see_more: 'Scroll down to see more items',
  decathlon_go_back_top: 'Top',
  decathlon_sortBy_label: 'Sort by',
  decathlon_sort_newest_label: 'Newest',
  decathlon_sort_oldest_label: 'Oldest',
  decathlon_sort_name_a_to_z_label: 'Name : A to Z',
  decathlon_sort_name_z_to_a_label: 'Name : Z to A',
  decathlon_sort_revelance_label: 'Relevance',
  decathlon_archives_label: 'Archives',
  decathlon_actions_label: 'Actions',
  decathlon_file_informations_label: 'File informations',
  decathlon_content_informations_label: 'Detailed informations',
  decathlon_createdAt_label: 'Creation date',
  decathlon_name_label: 'Document name',
  decathlon_open_label: 'Open',
  decathlon_download_label: 'Download',
  decathlon_go_back_label: 'Go back',
  decathlon_archived_label: 'ARCHIVE',
  decathlon_master_label: 'Master',
  decathlon_search_label: 'Search…',
  decathlon_results_sentence: 'results were found',
  decathlon_topic_label: 'Topic',
  decathlon_publisher_label: 'Publisher',
  decathlon_status_label: 'Status',
  decathlon_internalStatus_label: 'Internal status',
  decathlon_modifiedAt_label: 'Update date',
  decathlon_productModel_label: 'Product Models',
  decathlon_height_label: 'Height',
  decathlon_width_label: 'Width',
  decathlon_creator_label: 'Creator',
  decathlon_productSuperModelId_label: 'Product SuperModels',
  decathlon_componentModel_label: 'Component model',
  decathlon_componentId_label: 'Component ID',
  decathlon_componentComponentId_label: 'Refpack',
  decathlon_contentId_label: 'Content ID',
  decathlon_componentContentId_label: 'Code IPAC',
  decathlon_componentSuperModel_label: 'Component super model',
  decathlon_accessRights_label: 'Access rights',
  decathlon_publicUrl_label: 'Public url',
  decathlon_urlKameleon_label: 'Url kameleon',
  decathlon_filename_label: 'Title',
  decathlon_file_subtitle_label: 'FILE',
  decathlon_linked_products_subtitle_label: 'LINKED PRODUCTS',
  decathlon_complementary_data_subtitle_label: 'COMPLEMENTARY DATA',
  decathlon_version_label: 'Version',
  decathlon_status_page_doesnt_exist:
    "The page you were looking for doesn't exist.",
  decathlon_status_go_homepage: 'Go to home page'
};

export default enJSON;
