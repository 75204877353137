import cookie from 'cookie';
import { IncomingMessage } from 'http';
import * as jose from 'jose';

const ACCESS_TOKEN_KEY = 'access_token';

export const setCookie = (key: string, value: string) => {
  document.cookie = cookie.serialize(key, value, {
    maxAge: 30 * 24 * 60 * 60, // 30 days
    path: '/'
  });
};

export const removeCookie = (key: string) => {
  document.cookie = cookie.serialize(key, '', {
    maxAge: -1, // Expire the cookie immediately
    path: '/'
  });
};

export function getToken(req?: IncomingMessage | undefined) {
  let str = '';
  if (req && req.headers.cookie) {
    str = req.headers.cookie;
  } else if (typeof document !== 'undefined' && document.cookie) {
    str = document.cookie;
  }
  return cookie.parse(str)[ACCESS_TOKEN_KEY];
}

export function setTokenCookie(token: string) {
  setCookie(ACCESS_TOKEN_KEY, token);
}

export function removeTokenCookie() {
  removeCookie(ACCESS_TOKEN_KEY);
}

export const getDecodedJWT = (jwt: string | null) => {
  let decodedJwt = null;

  if (jwt) {
    try {
      decodedJwt = jose.decodeJwt(jwt);
    } catch (e) {
      console.error(e);
    }
  }
  return decodedJwt;
};
