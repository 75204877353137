import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Box, IconButton, SvgIconTypeMap, SxProps, Theme } from '@mui/material';
import MyTheme from '../theme/MyTheme';
import DefaultTooltip from '../components/tooltip/DefaultTooltip';
import { FC } from 'react';

const sxStyles: Record<string, SxProps<Theme>> = {
  block: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: MyTheme.spacing(1)
  },
  sectionDesktop: {
    display: 'none',
    [MyTheme.breakpoints.up('md')]: {
      display: 'flex'
    }
  }
};

type Props = {
  otherButtons: Array<{
    text: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    action?: () => void;
  }>;
};

const EndSectionDesktopHeader: FC<Props> = ({ otherButtons }) => {
  return (
    <Box sx={sxStyles.sectionDesktop}>
      {otherButtons.map((button, index) => {
        const Icon = button.icon;
        return (
          <Box key={index} sx={sxStyles.block}>
            <DefaultTooltip title={button.text}>
              <IconButton
                size="small"
                edge={index === otherButtons.length - 1 ? 'end' : false}
                aria-label="exit app"
                aria-haspopup="true"
                color="default"
                onClick={button.action}
              >
                <Icon />
              </IconButton>
            </DefaultTooltip>
          </Box>
        );
      })}
    </Box>
  );
};

export default EndSectionDesktopHeader;
