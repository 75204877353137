import { styled } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { alpha, InputBase, SxProps, Theme, Button, Box } from '@mui/material';
import MyTheme, { PRIMARY_COLOR } from '../theme/MyTheme';
import { COLOR_BLACK, DECATHLON_GREY_COLOR } from '../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch, resetFilters } from '../redux/actions/assets/assetsActions';
import { StateType } from '../redux/reducers/store';
import { useLocation, useNavigate } from 'react-router';
import { HOME_PAGE_PATH, SEARCH_PAGE_PATH } from '../constants/route';
import { FC, KeyboardEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../components/text/Text';
import usePrevious from '../hooks/usePrevious';
import { STATUS_LOADING, STATUS_SUCCESS } from '../types/status';
import { getHistory } from '../windowManager/windowManager';

const sxStyles: Record<string, SxProps<Theme>> = {
  icon: {
    color: PRIMARY_COLOR
  },
  searchBarLine: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    margin: MyTheme.spacing(0, 0, 0, 1)
  }
};

const Search = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(DECATHLON_GREY_COLOR, 0.9),
  '&:hover': {
    backgroundColor: alpha(DECATHLON_GREY_COLOR, 1)
  },
  width: '100%',
  maxWidth: 500,
  margin: MyTheme.spacing(1, 0, 1, 0)
}));

const SearchIconWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }: { theme: Theme }) => ({
  color: COLOR_BLACK,
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%'
  }
}));

const HeaderSearch: FC = () => {
  const inputRef = useRef<HTMLInputElement>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const combinedSearchUrlSearchParam = params.get('combinedSearch');
  const { combinedSearch, setSearchStatus } = useSelector(
    (state: StateType) => ({
      combinedSearch: state.assets.combinedSearch,
      setSearchStatus: state.assets.setSearchStatus
    })
  );
  const previousSetSearchStatus = usePrevious(setSearchStatus);
  const { t } = useTranslation();

  let timer: any;
  const waitTime = 700;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectToHomePage = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (location.pathname !== SEARCH_PAGE_PATH) {
      navigate(SEARCH_PAGE_PATH);
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;

    clearTimeout(timer);

    timer = setTimeout(() => {
      dispatch(setSearch(text));
      getHistory()?.replaceState(
        {},
        SEARCH_PAGE_PATH,
        `${location?.pathname}?combinedSearch=${text}`
      );
    }, waitTime);
  };

  const onReset = () => {
    dispatch(resetFilters());
    dispatch(setSearch(undefined));
    getHistory()?.replaceState({}, SEARCH_PAGE_PATH, `${location?.pathname}`);
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (
      previousSetSearchStatus === STATUS_LOADING &&
      setSearchStatus === STATUS_SUCCESS
    ) {
      navigate(HOME_PAGE_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousSetSearchStatus, setSearchStatus]);

  useEffect(() => {
    if (combinedSearchUrlSearchParam && combinedSearchUrlSearchParam !== '') {
      dispatch(setSearch(combinedSearchUrlSearchParam));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={sxStyles.searchBarLine}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon sx={sxStyles.icon} />
        </SearchIconWrapper>
        <StyledInputBase
          inputRef={inputRef}
          placeholder={t('decathlon_search_label')}
          inputProps={{ 'aria-label': 'search' }}
          onFocus={(e) => redirectToHomePage(e)}
          defaultValue={combinedSearch || combinedSearchUrlSearchParam || ''}
          onKeyUp={onKeyUp}
        />
      </Search>
      {inputRef?.current?.value && (
        <Button
          variant="contained"
          color="primary"
          sx={sxStyles.button}
          onClick={onReset}
        >
          <Text valuekey="decathlon_reset" />
        </Button>
      )}
    </Box>
  );
};
export default HeaderSearch;
